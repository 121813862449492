import React, { useEffect, useRef, useState } from 'react'
import colorOptions from '../../common/options';
import { bebasKaiFontData, horseBackFontData, primeTimeFontData, standardFontData, chunkFiveFontData } from '../../common/fontCalculation';
import { mapCharacter } from '../../common/imageGeneration';
import { useReactToPrint } from 'react-to-print';
import Select from 'react-select'
import { fontsArray } from '../../common/fonts';
import Modal from 'react-modal';
import { TASK_URL } from '../../config';

export default function Calculator() {

    const [data, setData] = useState()
    const [calculateView, setCalculateView] = useState(false)
    const [products, setProducts] = useState([])
    const [sleeveCount, setSleeveCount] = useState(null)
    const [message, setMessage] = useState(null)
    const [imageUrl, setImageUrl] = useState(null)
    const contentToPrint = useRef(null);
    const [loader, setLoader] = useState(false)
    const [supportView, setSupportView] = useState(false)
    const [supportData, setSupportData] = useState()
    const [supportMessage, setSupportMessage] = useState(null)

    useEffect(() => {
        if (supportMessage !== null) {
            setTimeout(() => {
                setSupportMessage(null)
            }, 5000)
        }
    }, [supportMessage])

    const handleChange = (e) => {
        setCalculateView(false)
        setLoader(false)
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleSupportChange = (e) => {
        setSupportData({
            ...supportData,
            [e.target.name]: e.target.value
        })
    }

    const handleSelectChange = (type, selectedOption) => {
        setLoader(false)
        setCalculateView(false);
        if (type === 'primaryColor' || type === 'outline1Color' || type === 'outline2Color') {
            setData(prevData => ({
                ...prevData,
                [type]: selectedOption.colorValue
            }));
        } else {
            setData(prevData => ({
                ...prevData,
                [type]: selectedOption.value
            }));
        }
    };

    const handleFont = async (selectedOption) => {
        if (selectedOption.value === 'STANDARD') {
            await handleSelectChange('height', { label: "3ft", value: '3' });
        } else {
            await handleSelectChange('height', { label: "4ft", value: '4' });
        }
    }

    function getVariantId(colorName) {
        const colorVariantMap = {
            "FFFFFF": "47487775801625",
            "FFFF00": "47487785042201",
            "1a8157": "47495040598297",
            "0705A6": "47495218528537",
            "000000": "47495235305753",
            "8B0000": "47496069480729",
            "FF5D8F": "47496089338137",
            "5d38ae": "47495004291353",
            "808080": "47495022739737",
            "D64B0F": "47495064551705",
            "006400": "47495100760345",
            "55252C": "47495117570329",
            "5E3C30": "47495148536089",
            "BF181D": "47495195164953",
            "2C65D9": "47495206699289",
            "282641": "47495225409817",
            "D79706": "47495242940697",
            "9F8310": "47495251329305",
            "008080": "47495252902169",
            "D1AB3E": "47496072888601",
            "8A8885": "47496076853529",
            "9A8154": "47496086389017",
        };

        return colorVariantMap[colorName] || null;
    }

    function getFontInfo(fontName) {
        const fontVariant = {
            "STANDARD": standardFontData,
            "BEBAS KAI": bebasKaiFontData,
            "HORSEBACK": horseBackFontData,
            "PRIMETIME": primeTimeFontData,
            "CHUNK FIVE": chunkFiveFontData,
        };

        return fontVariant[fontName] || null;
    }

    function getLabelByValue(value) {
        const colorOption = colorOptions.find(option => option.value === value);
        return colorOption ? colorOption.label : null;
    }

    function calculateSumForString(inputString, height, font) {
        const letterSum = {};

        // Convert the string to uppercase to handle both upper and lower case letters
        const upperCaseString = inputString.toUpperCase();

        // Iterate through each character in the string
        for (let i = 0; i < upperCaseString.length; i++) {
            const letter = upperCaseString[i];

            // Check if the character is a letter and exists in the excelData
            const letterData = getFontInfo(font).find(item => item.LETTER === letter && item.HEIGHT === `${height}'`);
            if (letterData) {
                const { LETTER, P, S, T, WIDTH } = letterData;

                // Update the letterSum object
                if (!letterSum[LETTER]) {
                    letterSum[LETTER] = { P: 0, S: 0, T: 0, WIDTH: 0 };
                }

                letterSum[LETTER].P += P;
                letterSum[LETTER].S += S;
                letterSum[LETTER].T += T;
                letterSum[LETTER].WIDTH += WIDTH
            }
        }

        // Initialize counts
        let totalCountP = 0;
        let totalCountS = 0;
        let totalCountT = 0;
        let totalWidth = 0;

        // Iterate through each letter in the letterSum object
        for (const letter in letterSum) {
            if (letterSum.hasOwnProperty(letter)) {
                const letterData = letterSum[letter];

                // Accumulate counts
                totalCountP += letterData.P;
                totalCountS += letterData.S;
                totalCountT += letterData.T;
                totalWidth += letterData.WIDTH
            }
        }

        // console.log(totalCountP)
        // console.log(totalCountS)
        // console.log(totalCountT)
        // console.log(totalWidth)

        // console.log(totalCountP / 50)
        // console.log(totalCountS / 50)
        // console.log(totalCountT / 50)


        return {
            P: Math.ceil(totalCountP / 50),
            S: Math.ceil(totalCountS / 50),
            T: Math.ceil(totalCountT / 50),
            totalWidth: totalWidth
        };
    }

    const handleCalculate = (e) => {
        e.preventDefault()
        setMessage(null)
        if (data === undefined) {
            setMessage("Please Enter Text, Select Font and Height");
        } else {
            const { primaryColor, outline1Color, outline2Color, text, height, font } = data;
            if (!text || !height || !font || (primaryColor === undefined && outline1Color === undefined && outline2Color === undefined)) {
                setMessage("Please enter text, select font & height, select at least one color");
            }
            else {
                const result = calculateSumForString(text, height, font);
                setSleeveCount(result);

                const products = [];

                // Check if primary color exists
                if (primaryColor) {
                    // Define the first product object
                    const product1 = {
                        name: `${getLabelByValue(primaryColor)} Sleeve (50 Cups Per Sleeve) - Primary Color`,
                        quantity: result.P,
                        price: 11,
                        total: result.P * 11,
                        colors: primaryColor,
                        // variantId: `gid://shopify/ProductVariant/${getVariantId(primaryColor)}`
                        variantId: `${getVariantId(primaryColor)}`
                    };
                    products.push(product1);
                }

                // Check if outline1Color exists
                if (outline1Color !== 'None' && outline1Color !== undefined) {
                    // Define the second product object
                    const product2 = {
                        name: `${getLabelByValue(outline1Color)} Sleeve (50 Cups Per Sleeve) - Outline 1 Color`,
                        quantity: result.S,
                        price: 11,
                        total: result.S * 11,
                        colors: outline1Color,
                        // variantId: `gid://shopify/ProductVariant/${getVariantId(outline1Color)}`
                        variantId: `${getVariantId(outline1Color)}`
                    };

                    products.push(product2);

                }
                if (outline2Color !== 'None' && outline2Color !== undefined) {
                    // Define the second product object
                    const product2 = {
                        name: `${getLabelByValue(outline2Color)} Sleeve (50 Cups Per Sleeve) - Outline 2 Color`,
                        quantity: result.T,
                        price: 11,
                        total: result.T * 11,
                        colors: outline2Color,
                        // variantId: `gid://shopify/ProductVariant/${getVariantId(outline2Color)}`
                        variantId: `${getVariantId(outline2Color)}`
                    };

                    products.push(product2);

                }
                setCalculateView(true)
                setProducts(products)

                renderComposedImage(data?.text, data?.primaryColor, data?.outline1Color, data?.outline2Color, data?.font, data?.height, data?.height)
                const customerNote = `
                Text: ${data?.text.toUpperCase()}\n
                Font: ${data?.font}\n
                Height: ${data?.height}ft\n
                  Primary (Main): ${!data.primaryColor ? "n/a" : `${getLabelByValue(data?.primaryColor)} ${result?.P} Sleeves`}
        ${(!data.outline1Color || data.outline1Color === 'None') ?
                        `Outline 1 (Middle): n/a` :
                        `Outline 1 (Middle): ${getLabelByValue(data?.outline1Color)} ${result?.S} Sleeves`}
        ${(!data.outline2Color || data.outline2Color === 'None') ?
                        `Outline 2 (Exterior): n/a` :
                        `Outline 2 (Exterior): ${getLabelByValue(data?.outline2Color)} ${result?.T} Sleeves`}
                `

                let message = {
                    productValues: products.map((item) => {
                        return {
                            variant: item.variantId,
                            quantity: item.quantity
                        };
                    }),
                    customerNote: customerNote
                }

                window.parent.postMessage(message, "*");

            }
        }
    }

    const getTotal = () => {
        return products.reduce((accumulator, product) => accumulator + product.total, 0)
    }

    function renderComposedImage(text, primaryColor, outline1Color, outline2Color, tfont, tsizefolder, tsize) {
        setLoader(true);
        setImageUrl(null);
        let composedCanvas = document.createElement('canvas');
        let composedCtx = composedCanvas.getContext('2d');
    
        let imageSize = tsizefolder === "3" ? 55 : 65;
    
        // Set the size of the composed canvas
        composedCanvas.width = tsizefolder + 10 * text.length;
        composedCanvas.height = 85;
    
        let xOffset = 0;
    
        function renderCharacter(ch, index) {
            if (ch === " ") {
                xOffset += 25;
                if (index + 1 < text.length) {
                    renderCharacter(text[index + 1], index + 1);
                } else {
                    let composedDataURL = composedCanvas.toDataURL();
                    setImageUrl(composedDataURL);
                    setLoader(false);
                }
                return;
            }
    
            // Check if at least one color is defined, otherwise skip loading images
            if (!primaryColor && !outline1Color && !outline2Color) {
                setLoader(false);
                return;
            }
    
            let source1 = new Image();
            let source2 = new Image();
            let source3 = new Image();
    
            source1.onload = function () {
                let canvas1 = document.createElement('canvas');
                let ctx1 = canvas1.getContext('2d');
                canvas1.width = imageSize;
                canvas1.height = imageSize;
                ctx1.drawImage(source1, 0, 0, imageSize, imageSize);
    
                source2.onload = function () {
                    let canvas2 = document.createElement('canvas');
                    let ctx2 = canvas2.getContext('2d');
                    canvas2.width = imageSize;
                    canvas2.height = imageSize;
                    ctx2.drawImage(source2, 0, 0, imageSize, imageSize);
    
                    source3.onload = function () {
                        let canvas3 = document.createElement('canvas');
                        let ctx3 = canvas3.getContext('2d');
                        canvas3.width = imageSize;
                        canvas3.height = imageSize;
                        ctx3.drawImage(source3, 0, 0, imageSize, imageSize);
    
                        function changeColor(ctx, canvas, color) {
                            let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                            let data = imageData.data;
                            for (let i = 0; i < data.length; i += 4) {
                                if (data[i + 3] > 0) {
                                    data[i] = color[0];
                                    data[i + 1] = color[1];
                                    data[i + 2] = color[2];
                                }
                            }
                            ctx.putImageData(imageData, 0, 0);
                        }
    
                        if (primaryColor) changeColor(ctx1, canvas1, hexToRgb(primaryColor));
                        if (outline1Color) changeColor(ctx2, canvas2, hexToRgb(outline1Color));
                        if (outline2Color) changeColor(ctx3, canvas3, hexToRgb(outline2Color));
    
                        composedCtx.drawImage(canvas1, xOffset, 0);
                        if (outline1Color) composedCtx.drawImage(canvas2, xOffset, 0);
                        if (outline2Color) composedCtx.drawImage(canvas3, xOffset, 0);
    
                        xOffset += imageSize - 10;
    
                        if (index + 1 < text.length) {
                            renderCharacter(text[index + 1], index + 1);
                        } else {
                            let composedDataURL = composedCanvas.toDataURL();
                            setImageUrl(composedDataURL);
                            setLoader(false);
                        }
                    };
    
                    if (outline2Color) {
                        source3.src = `./images/utils/Images/${tfont}/Export PNG/${tsizefolder}'/${tfont}_${tsize}'_${mapCharacter(ch)}(T).png`;
                    } else {
                        source3.onload();
                    }
                };
    
                if (outline1Color) {
                    source2.src = `./images/utils/Images/${tfont}/Export PNG/${tsizefolder}'/${tfont}_${tsize}'_${mapCharacter(ch)}(S).png`;
                } else {
                    source2.onload();
                }
            };
    
            if (primaryColor) {
                source1.src = `./images/utils/Images/${tfont}/Export PNG/${tsizefolder}'/${tfont}_${tsize}'_${mapCharacter(ch)}(P).png`;
            } else {
                source1.onload();
            }
        }
    
        if (text.length > 0) {
            renderCharacter(text[0], 0);
        }
    }
    


    function hexToRgb(hex) {
        if (hex === undefined || hex === null) {
            return "rgba(0, 0, 0, 0);" // Transparent color
        }

        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, (m, r, g, b) => {
            return r + r + g + g + b + b;
        });

        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? [
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16),
            0 // Alpha value set to 0 for full transparency
        ] : null;
    }


    const handlePrint = useReactToPrint({
        documentTitle: "Print This Document",
        onBeforePrint: () => console.log("before printing..."),
        onAfterPrint: () => console.log("after printing..."),
        removeAfterPrint: true,
    });

    const getExpectedImage = (colorValue) => {
        const selectedColor = colorOptions.find(option => option.value === colorValue);
        return selectedColor ? selectedColor.image : null;
    }

    const createSupportTicket = async (e) => {
        e.preventDefault()
        fetch(
            `${TASK_URL}/manage_userstories.php`,
            {
                method: 'POST',
                body: JSON.stringify({
                    action: "addMainTaskFromOthers",
                    title: supportData.title + '\n' + supportData.email_to_contact,
                    description: supportData.description,
                    projectId: '61430869046e88aab2bf40ac588f209b'
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            }
        ).then((response) => response.json())
            .then((result) => {
                setSupportMessage("Your ticket has been created. Someone from our customer service team will review it and respond shortly.")
                setSupportView(false)
            })
            .catch((error) => {
                console.error('Error:', error);
                setSupportView(false)
            });
    }

    useEffect(() => {
        colorOptions?.forEach((item) => {
            const img = new Image();
            img.src = item.image; // Preloading the image
        });
        // eslint-disable-next-line
    }, [colorOptions]);

    return (
        <div ref={contentToPrint}>
            <div className='container mt-2 mb-2' >
                <h3 className='mb-3'>Font Calculator</h3>
                <div className='calc-container' >
                    <form onSubmit={(e) => handleCalculate(e)}>
                        <div className='form-group p-4'>
                            <div className='row col-lg-12 col-md-12'>
                                <div className='col-lg-4 col-md-4'>
                                    <label htmlFor="text" className="form-label">Enter text</label>
                                    <input type="text" name="text" onChange={(e) => handleChange(e)} className="form-control" id="text" required />
                                </div>
                                <div className='col-lg-4 col-md-4'>
                                    <label htmlFor="font" className="form-label">Select Font</label>
                                    <Select
                                        options={fontsArray}
                                        onChange={(value) => {
                                            handleSelectChange("font", value);
                                            handleFont(value);
                                        }}
                                        placeholder=""
                                    />

                                </div>
                                <div className='col-lg-4 col-md-4'>
                                    <label htmlFor="height" className="form-label">Select Height</label>
                                    <Select
                                        options={data?.font === 'STANDARD' ? [
                                            { value: "3", label: "3ft" },
                                        ] : [
                                            { value: "4", label: "4ft" },
                                            { value: "5", label: "5ft" },
                                        ]}
                                        onChange={(value) => handleSelectChange("height", value)}
                                        value={{ label: data?.height !== undefined ? `${data?.height}ft` : "" }}
                                        placeholder="Select Height"
                                    />

                                </div>
                            </div>
                            <div className='row col-lg-12 col-md-12 mt-1'>
                                <label>Select Color:</label>
                                <div className='col-lg-4 col-md-4'>
                                    <Select
                                        id="p-color"
                                        options={colorOptions?.filter(x => {
                                            return x.value !== 'None'
                                        }).map((item) => {
                                            return { value: `${item.value} -${item.label}`, colorValue: item.value, label: <label key={item.label}>{item.label}{" "}<img style={{ width: '50px' }} alt="" src={item.image} /> </label> }
                                        })}
                                        onChange={(value) => handleSelectChange("primaryColor", value)}
                                        value={{ label: <label>Primary(Main){" "}<img style={{ width: '25px' }} alt="" src={getExpectedImage(data?.primaryColor)} /> </label> }}

                                    />
                                </div>
                                <div className='col-lg-4 col-md-4'>
                                    <Select
                                        id="o1-color"
                                        options={colorOptions?.map((item) => {
                                            return { value: `${item.value} -${item.label}`, colorValue: item.value, label: <label key={item.label}>{item.label}{" "}<img style={{ width: '50px' }} alt="" src={item.image} /> </label> }
                                        })}
                                        onChange={(value) => handleSelectChange("outline1Color", value)}
                                        value={{ label: data?.outline1Color === 'None' ? data?.outline1Color : <label>Outline 1(Middle){" "}<img style={{ width: '25px' }} alt="" src={getExpectedImage(data?.outline1Color)} /> </label> }}

                                    />
                                </div>

                                <div className='col-lg-4 col-md-4'>
                                    <Select
                                        id="o2-color"
                                        options={colorOptions?.map((item) => {
                                            return { value: `${item.value} -${item.label}`, colorValue: item.value, label: <label key={item.label}>{item.label}{" "}<img style={{ width: '50px' }} alt="" src={item.image} /> </label> }
                                        })}
                                        onChange={(value) => handleSelectChange("outline2Color", value)}
                                        value={{ label: data?.outline2Color === 'None' ? data?.outline2Color : <label>Outline 2(Exterior){" "}<img style={{ width: '25px' }} alt="" src={getExpectedImage(data?.outline2Color)} /> </label> }}
                                    />
                                </div>
                            </div>
                            <div className='mt-4'>
                                <button onClick={() => {
                                }} type="submit" className='btn btn-secondary'>
                                    {loader ?
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only"></span>
                                        </div>

                                        :
                                        "Calculate"}
                                </button>
                                {
                                    loader ?
                                        <label className='m-1'>Loading, please wait....</label>
                                        :
                                        null
                                }
                                {/* <div style={{ float: 'right' }}>
                                    <label onClick={() => setSupportView(true)} style={{ cursor: 'pointer' }}>Support <i class="bi bi-question-circle"></i></label>
                                </div> */}
                            </div>
                            {
                                message !== null &&
                                <label className='text-danger mt-2'>{message}</label>
                            }
                            {
                                supportMessage !== null &&
                                <label className='text-success mt-2'>{supportMessage}</label>
                            }
                        </div>
                    </form>
                </div>
                {
                    calculateView && loader === false &&
                    <>
                        <div className='mt-3 char-generator'>
                            <div>
                                <div style={{ marginLeft: '6%' }}>
                                    <img src={imageUrl} alt="" style={{ marginTop: '30px' }} />
                                </div>
                            </div>
                        </div>
                        <div className='mt-2'>
                            <label><b>Height:</b> {data?.height}ft , <b>Length:</b> {sleeveCount?.totalWidth}ft</label><br />
                            <label><b>Primary (Main): </b>{!data.primaryColor ? "n/a" : `${sleeveCount?.P} Sleeves =$${sleeveCount.P * 11} USD`} </label><br />
                            {
                                data.outline1Color !== 'None' && data.outline1Color !== undefined &&
                                <>
                                    <label><b>Outline 1 (Middle): </b>{!data.outline1Color ? "n/a" : `${sleeveCount?.S} Sleeves =$${sleeveCount.S * 11} USD`}</label><br />
                                </>
                            }
                            {
                                data.outline2Color !== 'None' && data.outline2Color !== undefined &&
                                <>
                                    <label><b>Outline 2 (Exterior): </b>{!data.outline2Color ? "n/a" : `${sleeveCount?.T} Sleeves = $${sleeveCount.T * 11} USD`}</label><br />
                                </>
                            }
                            <label><b>Total Cost: </b>${getTotal()} USD</label><br />
                        </div>
                        <div className='mt-4 mb-4 calc-container'>
                            <div className='container p-1'>
                                <div className='m-4'>
                                    <div className='mt-2 mb-2'>
                                        <button onClick={() => {
                                            handlePrint(null, () => contentToPrint.current)
                                        }
                                        } type="button" className='btn btn-secondary'>Print <img src='./images/utils/Images/CUPSIMG/printer.svg'/> </button>
                                    </div>
                                    <table className='table table-bordered'>
                                        <thead>
                                            <tr>
                                                <th>Items</th>
                                                <th>QTY</th>
                                                <th>PRICE</th>
                                                <th>TOTAL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                products.map((item, index) => {
                                                    return <tr key={index}>
                                                        <td><img style={{ width: '50px' }} alt="" src={getExpectedImage(item?.colors)} />{" "}{item?.name}</td>
                                                        <td>{item?.quantity}</td>
                                                        <td>${item?.price} USD</td>
                                                        <td>${item?.total} USD</td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <label>Preview</label>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6'>
                                            <div className='mt-3 char-generator'>
                                                <div>
                                                    <div style={{ marginLeft: '6%' }}>
                                                        <img src={imageUrl} alt="" style={{ marginTop: '30px' }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2 mt-3'>
                                            <label><b>Text:</b> {data?.text.toUpperCase()}</label><br />
                                            <label><b>Font:</b> {data?.font}</label><br />
                                            <label><b>Height:</b> {data?.height}ft<br /><b>Length:</b> {sleeveCount?.totalWidth}ft</label>
                                        </div>
                                        <div className='col-lg-3 col-md-3 mt-3'>
                                            <label>TOTAL: ${getTotal()} USD</label>
                                            <br />
                                            <label style={{ fontSize: '10px' }}>No Taxes Are Charged. Shipping is Calculated at checkout</label>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
            <Modal
                isOpen={supportView}
                onRequestClose={() => {
                    setSupportView(false)
                }}
                style={customStyles}
                shouldCloseOnOverlayClick={false}
            >
                <div className='container'>
                    <center className='mt-2 mb-3'>
                        <h5>HOW CAN WE HELP YOU?</h5>
                    </center>
                    <form onSubmit={(e) => createSupportTicket(e)}>
                        <div className='container'>
                            <div className='card'>
                                <div className='card-header'>
                                    <h5>Tell us about the issue you are facing!</h5>
                                </div>
                                <div className='card-body'>
                                    <div className='form-group m-2'>
                                        <label>Email?</label>
                                        <input name='email_to_contact' type="email" className='form-control' onChange={(e) => handleSupportChange(e)} required />
                                    </div>
                                    <div className='form-group m-2'>
                                        <label>Could You Please Describe This Issue In One Sentence?</label>
                                        <textarea name="title" col={4} className='form-control' onChange={(e) => handleSupportChange(e)} required />
                                    </div>
                                    <div className='form-group m-2'>
                                        <label>Let Us Know How To Reproduce The Issue For Us To Work On?</label>
                                        <textarea name='description' col={4} className='form-control' onChange={(e) => handleSupportChange(e)} required />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <center className='mt-2'>
                            <button type="submit" className="btn btn-secondary btn-sm m-2">Save</button>
                            <button type="button" className="btn btn-danger btn-sm" onClick={() => {
                                setSupportView(false)
                            }}>close</button>
                            <br />
                        </center>
                    </form>
                </div>
            </Modal>
        </div>
    )
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        height: '85%',
        fontSize: '11px'
    },
    overlay: { zIndex: 9999999999 }
};
export const bebasKaiFontData = [

    { "LETTER": "A", "WIDTH": 3.5, "HEIGHT": "4'", "P": 126, "S": 59, "T": 55 },
    { "LETTER": "B", "WIDTH": 3, "HEIGHT": "4'", "P": 138, "S": 58, "T": 53 },
    { "LETTER": "C", "WIDTH": 3, "HEIGHT": "4'", "P": 108, "S": 58, "T": 60 },
    { "LETTER": "D", "WIDTH": 3, "HEIGHT": "4'", "P": 128, "S": 58, "T": 58 },
    { "LETTER": "E", "WIDTH": 3, "HEIGHT": "4'", "P": 110, "S": 56, "T": 60 },
    { "LETTER": "F", "WIDTH": 3, "HEIGHT": "4'", "P": 90, "S": 96, "T": 100 },
    { "LETTER": "G", "WIDTH": 3, "HEIGHT": "4'", "P": 121, "S": 64, "T": 100 },
    { "LETTER": "H", "WIDTH": 3, "HEIGHT": "4'", "P": 126, "S": 59, "T": 63 },
    { "LETTER": "I", "WIDTH": 2, "HEIGHT": "4'", "P": 58, "S": 30, "T": 34 },
    { "LETTER": "J", "WIDTH": 3, "HEIGHT": "4'", "P": 83, "S": 44, "T": 97 },
    { "LETTER": "K", "WIDTH": 3, "HEIGHT": "4'", "P": 117, "S": 61, "T": 57 },
    { "LETTER": "L", "WIDTH": 3, "HEIGHT": "4'", "P": 83, "S": 40, "T": 44 },
    { "LETTER": "M", "WIDTH": 3, "HEIGHT": "4'", "P": 137, "S": 57, "T": 56 },
    { "LETTER": "N", "WIDTH": 3, "HEIGHT": "4'", "P": 200, "S": 58, "T": 100 },
    { "LETTER": "O", "WIDTH": 3, "HEIGHT": "4'", "P": 120, "S": 56, "T": 54 },
    { "LETTER": "P", "WIDTH": 3, "HEIGHT": "4'", "P": 108, "S": 100, "T": 100 },
    { "LETTER": "Q", "WIDTH": 3, "HEIGHT": "4'", "P": 127, "S": 55, "T": 99 },
    { "LETTER": "R", "WIDTH": 3, "HEIGHT": "4'", "P": 130, "S": 60, "T": 59 },
    { "LETTER": "S", "WIDTH": 3, "HEIGHT": "4'", "P": 129, "S": 66, "T": 58 },
    { "LETTER": "T", "WIDTH": 3, "HEIGHT": "4'", "P": 88, "S": 42, "T": 96 },
    { "LETTER": "U", "WIDTH": 3, "HEIGHT": "4'", "P": 118, "S": 58, "T": 61 },
    { "LETTER": "V", "WIDTH": 4, "HEIGHT": "4'", "P": 114, "S": 60, "T": 61 },
    { "LETTER": "W", "WIDTH": 6, "HEIGHT": "4'", "P": 147, "S": 94, "T": 89 },
    { "LETTER": "X", "WIDTH": 3, "HEIGHT": "4'", "P": 119, "S": 60, "T": 58 },
    { "LETTER": "Y", "WIDTH": 3, "HEIGHT": "4'", "P": 85, "S": 98, "T": 99 },
    { "LETTER": "Z", "WIDTH": 3, "HEIGHT": "4'", "P": 105, "S": 58, "T": 62 },

    { "LETTER": " ", "WIDTH": 2, "HEIGHT": "4'", "P": 0, "S": 0, "T": 0 },
    { "LETTER": "0", "WIDTH": 3, "HEIGHT": "4'", "P": 120, "S": 56, "T": 54 },
    { "LETTER": "1", "WIDTH": 2, "HEIGHT": "4'", "P": 62, "S": 32, "T": 36 },
    { "LETTER": "2", "WIDTH": 3, "HEIGHT": "4'", "P": 126, "S": 66, "T": 66 },
    { "LETTER": "3", "WIDTH": 3, "HEIGHT": "4'", "P": 128, "S": 69, "T": 60 },
    { "LETTER": "4", "WIDTH": 3, "HEIGHT": "4'", "P": 108, "S": 96, "T": 96 },
    { "LETTER": "5", "WIDTH": 3, "HEIGHT": "4'", "P": 128, "S": 67, "T": 62 },
    { "LETTER": "6", "WIDTH": 3, "HEIGHT": "4'", "P": 136, "S": 66, "T": 57 },
    { "LETTER": "7", "WIDTH": 3, "HEIGHT": "4'", "P": 86, "S": 100, "T": 53 },
    { "LETTER": "8", "WIDTH": 3, "HEIGHT": "4'", "P": 142, "S": 62, "T": 56 },
    { "LETTER": "9", "WIDTH": 3, "HEIGHT": "4'", "P": 136, "S": 66, "T": 57 },
    { "LETTER": "'", "WIDTH": 2, "HEIGHT": "4'", "P": 18, "S": 15, "T": 19 },
    { "LETTER": ",", "WIDTH": 2, "HEIGHT": "4'", "P": 18, "S": 15, "T": 19 },
    { "LETTER": "!", "WIDTH": 2, "HEIGHT": "4'", "P": 52, "S": 35, "T": 34 },
    { "LETTER": "#", "WIDTH": 4, "HEIGHT": "4'", "P": 200, "S": 74, "T": 68 },
    { "LETTER": "-", "WIDTH": 3, "HEIGHT": "4'", "P": 28, "S": 20, "T": 24 },
    { "LETTER": ".", "WIDTH": 2, "HEIGHT": "4'", "P": 14, "S": 13, "T": 17 },
    { "LETTER": "?", "WIDTH": 3, "HEIGHT": "4'", "P": 87, "S": 56, "T": 51 },
    { "LETTER": "@", "WIDTH": 4.5, "HEIGHT": "4'", "P": 152, "S": 118, "T": 75 },
    { "LETTER": "$", "WIDTH": 2.5, "HEIGHT": "4'", "P": 81, "S": 55, "T": 52 },
    { "LETTER": "%", "WIDTH": 4, "HEIGHT": "4'", "P": 119, "S": 102, "T": 84 },
    { "LETTER": "^", "WIDTH": 3, "HEIGHT": "4'", "P": 26, "S": 29, "T": 32 },
    { "LETTER": "&", "WIDTH": 3.5, "HEIGHT": "4'", "P": 131, "S": 70, "T": 55 },
    { "LETTER": "*", "WIDTH": 2.5, "HEIGHT": "4'", "P": 102, "S": 36, "T": 33 },
    { "LETTER": "(", "WIDTH": 2.5, "HEIGHT": "4'", "P": 58, "S": 38, "T": 42 },
    { "LETTER": ")", "WIDTH": 2.5, "HEIGHT": "4'", "P": 58, "S": 38, "T": 42 },
    { "LETTER": "_", "WIDTH": 3.5, "HEIGHT": "4'", "P": 36, "S": 24, "T": 28 },
    { "LETTER": "=", "WIDTH": 3, "HEIGHT": "4'", "P": 56, "S": 40, "T": 30 },
    { "LETTER": "+", "WIDTH": 3.5, "HEIGHT": "4'", "P": 73, "S": 36, "T": 40 },

    { "LETTER": "A", "WIDTH": 4, "HEIGHT": "5'", "P": 152, "S": 67, "T": 66 },
    { "LETTER": "B", "WIDTH": 3.5, "HEIGHT": "5'", "P": 247, "S": 80, "T": 76 },
    { "LETTER": "C", "WIDTH": 3.5, "HEIGHT": "5'", "P": 153, "S": 76, "T": 80 },
    { "LETTER": "D", "WIDTH": 3.5, "HEIGHT": "5'", "P": 180, "S": 76, "T": 76 },
    { "LETTER": "E", "WIDTH": 3.5, "HEIGHT": "5'", "P": 168, "S": 74, "T": 78 },
    { "LETTER": "F", "WIDTH": 3.5, "HEIGHT": "5'", "P": 133, "S": 60, "T": 64 },
    { "LETTER": "G", "WIDTH": 3.5, "HEIGHT": "5'", "P": 173, "S": 84, "T": 79 },
    { "LETTER": "H", "WIDTH": 3.5, "HEIGHT": "5'", "P": 168, "S": 74, "T": 78 },
    { "LETTER": "I", "WIDTH": 2, "HEIGHT": "5'", "P": 73, "S": 36, "T": 40 },
    { "LETTER": "J", "WIDTH": 3.5, "HEIGHT": "5'", "P": 113, "S": 56, "T": 60 },
    { "LETTER": "K", "WIDTH": 3.5, "HEIGHT": "5'", "P": 161, "S": 80, "T": 79 },
    { "LETTER": "L", "WIDTH": 3.5, "HEIGHT": "5'", "P": 108, "S": 100, "T": 54 },
    { "LETTER": "M", "WIDTH": 3.5, "HEIGHT": "5'", "P": 213, "S": 77, "T": 68 },
    { "LETTER": "N", "WIDTH": 3.5, "HEIGHT": "5'", "P": 204, "S": 80, "T": 76 },
    { "LETTER": "O", "WIDTH": 3.5, "HEIGHT": "5'", "P": 170, "S": 76, "T": 76 },
    { "LETTER": "P", "WIDTH": 3.5, "HEIGHT": "5'", "P": 200, "S": 64, "T": 64 },
    { "LETTER": "Q", "WIDTH": 3.5, "HEIGHT": "5'", "P": 185, "S": 73, "T": 68 },
    { "LETTER": "R", "WIDTH": 3.5, "HEIGHT": "5'", "P": 183, "S": 80, "T": 79 },
    { "LETTER": "S", "WIDTH": 3.5, "HEIGHT": "5'", "P": 178, "S": 84, "T": 84 },
    { "LETTER": "T", "WIDTH": 4, "HEIGHT": "5'", "P": 113, "S": 52, "T": 56 },
    { "LETTER": "U", "WIDTH": 3.5, "HEIGHT": "5'", "P": 158, "S": 74, "T": 78 },
    { "LETTER": "V", "WIDTH": 4, "HEIGHT": "5'", "P": 137, "S": 71, "T": 72 },
    { "LETTER": "W", "WIDTH": 6, "HEIGHT": "5'", "P": 243, "S": 116, "T": 111 },
    { "LETTER": "X", "WIDTH": 3.5, "HEIGHT": "5'", "P": 154, "S": 82, "T": 78 },
    { "LETTER": "Y", "WIDTH": 3.5, "HEIGHT": "5'", "P": 110, "S": 63, "T": 66 },
    { "LETTER": "Z", "WIDTH": 3.5, "HEIGHT": "5'", "P": 168, "S": 74, "T": 78 },

    { "LETTER": " ", "WIDTH": 2, "HEIGHT": "5'", "P": 0, "S": 0, "T": 0 },
    { "LETTER": "0", "WIDTH": 3.5, "HEIGHT": "5'", "P": 170, "S": 76, "T": 76 },
    { "LETTER": "1", "WIDTH": 2, "HEIGHT": "5'", "P": 83, "S": 40, "T": 44 },
    { "LETTER": "2", "WIDTH": 3.5, "HEIGHT": "5'", "P": 160, "S": 80, "T": 82 },
    { "LETTER": "3", "WIDTH": 3.5, "HEIGHT": "5'", "P": 162, "S": 82, "T": 81 },
    { "LETTER": "4", "WIDTH": 3.5, "HEIGHT": "5'", "P": 196, "S": 64, "T": 60 },
    { "LETTER": "5", "WIDTH": 3.5, "HEIGHT": "5'", "P": 173, "S": 84, "T": 82 },
    { "LETTER": "6", "WIDTH": 3.5, "HEIGHT": "5'", "P": 179, "S": 84, "T": 80 },
    { "LETTER": "7", "WIDTH": 3.5, "HEIGHT": "5'", "P": 115, "S": 64, "T": 66 },
    { "LETTER": "8", "WIDTH": 3, "HEIGHT": "5'", "P": 175, "S": 76, "T": 70 },
    { "LETTER": "9", "WIDTH": 3.5, "HEIGHT": "5'", "P": 179, "S": 84, "T": 80 },
    { "LETTER": "'", "WIDTH": 2, "HEIGHT": "5'", "P": 21, "S": 16, "T": 20 },
    { "LETTER": ",", "WIDTH": 2, "HEIGHT": "5'", "P": 21, "S": 16, "T": 20 },
    { "LETTER": "!", "WIDTH": 2, "HEIGHT": "5'", "P": 72, "S": 43, "T": 42 },
    { "LETTER": "#", "WIDTH": 5, "HEIGHT": "5'", "P": 228, "S": 104, "T": 146 },
    { "LETTER": "-", "WIDTH": 3, "HEIGHT": "5'", "P": 38, "S": 22, "T": 26 },
    { "LETTER": ".", "WIDTH": 2, "HEIGHT": "5'", "P": 17, "S": 14, "T": 18 },
    { "LETTER": "?", "WIDTH": 3.5, "HEIGHT": "5'", "P": 115, "S": 65, "T": 64 },
    { "LETTER": "@", "WIDTH": 5, "HEIGHT": "5'", "P": 236, "S": 144, "T": 107 },
    { "LETTER": "$", "WIDTH": 3, "HEIGHT": "5'", "P": 126, "S": 70, "T": 66 },
    { "LETTER": "%", "WIDTH": 4.5, "HEIGHT": "5'", "P": 166, "S": 128, "T": 116 },
    { "LETTER": "^", "WIDTH": 3, "HEIGHT": "5'", "P": 102, "S": 34, "T": 37 },
    { "LETTER": "&", "WIDTH": 4.5, "HEIGHT": "5'", "P": 223, "S": 103, "T": 88 },
    { "LETTER": "*", "WIDTH": 3, "HEIGHT": "5'", "P": 74, "S": 43, "T": 42 },
    { "LETTER": "(", "WIDTH": 2.5, "HEIGHT": "5'", "P": 76, "S": 52, "T": 53 },
    { "LETTER": ")", "WIDTH": 2.5, "HEIGHT": "5'", "P": 76, "S": 52, "T": 52 },
    { "LETTER": "_", "WIDTH": 3.5, "HEIGHT": "5'", "P": 52, "S": 26, "T": 30 },
    { "LETTER": "=", "WIDTH": 3, "HEIGHT": "5'", "P": 22, "S": 35, "T": 32 },
    { "LETTER": "+", "WIDTH": 3.5, "HEIGHT": "5'", "P": 93, "S": 44, "T": 52 },
]

export const chunkFiveFontData = [

    { "LETTER": "A", "WIDTH": 5, "HEIGHT": "4'", "P": 186, "S": 73, "T": 65 },
    { "LETTER": "B", "WIDTH": 5, "HEIGHT": "4'", "P": 237, "S": 78, "T": 70 },
    { "LETTER": "C", "WIDTH": 5, "HEIGHT": "4'", "P": 186, "S": 81, "T": 84 },
    { "LETTER": "D", "WIDTH": 5, "HEIGHT": "4'", "P": 206, "S": 76, "T": 76 },
    { "LETTER": "E", "WIDTH": 5, "HEIGHT": "4'", "P": 215, "S": 83, "T": 80 },
    { "LETTER": "F", "WIDTH": 5, "HEIGHT": "4'", "P": 178, "S": 69, "T": 68 },
    { "LETTER": "G", "WIDTH": 5, "HEIGHT": "4'", "P": 220, "S": 90, "T": 79 },
    { "LETTER": "H", "WIDTH": 5, "HEIGHT": "4'", "P": 234, "S": 78, "T": 70 },
    { "LETTER": "I", "WIDTH": 3.5, "HEIGHT": "4'", "P": 141, "S": 56, "T": 60 },
    { "LETTER": "J", "WIDTH": 5, "HEIGHT": "4'", "P": 200, "S": 62, "T": 66 },
    { "LETTER": "K", "WIDTH": 5, "HEIGHT": "4'", "P": 207, "S": 91, "T": 75 },
    { "LETTER": "L", "WIDTH": 5, "HEIGHT": "4'", "P": 158, "S": 62, "T": 66 },
    { "LETTER": "M", "WIDTH": 6, "HEIGHT": "4'", "P": 281, "S": 115, "T": 88 },
    { "LETTER": "N", "WIDTH": 5, "HEIGHT": "4'", "P": 235, "S": 82, "T": 66 },
    { "LETTER": "O", "WIDTH": 5, "HEIGHT": "4'", "P": 198, "S": 76, "T": 76 },
    { "LETTER": "P", "WIDTH": 5, "HEIGHT": "4'", "P": 248, "S": 72, "T": 69 },
    { "LETTER": "Q", "WIDTH": 5, "HEIGHT": "4'", "P": 217, "S": 80, "T": 70 },
    { "LETTER": "R", "WIDTH": 5, "HEIGHT": "4'", "P": 229, "S": 84, "T": 76 },
    { "LETTER": "S", "WIDTH": 5, "HEIGHT": "4'", "P": 225, "S": 90, "T": 72 },
    { "LETTER": "T", "WIDTH": 5, "HEIGHT": "4'", "P": 165, "S": 64, "T": 66 },
    { "LETTER": "U", "WIDTH": 5, "HEIGHT": "4'", "P": 244, "S": 74, "T": 78 },
    { "LETTER": "V", "WIDTH": 5, "HEIGHT": "4'", "P": 198, "S": 73, "T": 72 },
    { "LETTER": "W", "WIDTH": 6, "HEIGHT": "4'", "P": 239, "S": 106, "T": 87 },
    { "LETTER": "X", "WIDTH": 5, "HEIGHT": "4'", "P": 191, "S": 94, "T": 88 },
    { "LETTER": "Y", "WIDTH": 5, "HEIGHT": "4'", "P": 199, "S": 63, "T": 63 },
    { "LETTER": "Z", "WIDTH": 5, "HEIGHT": "4'", "P": 181, "S": 88, "T": 88 },

    { "LETTER": " ", "WIDTH": 2, "HEIGHT": "4'", "P": 0, "S": 0, "T": 0 },
    { "LETTER": "0", "WIDTH": 4, "HEIGHT": "4'", "P": 176, "S": 64, "T": 64 },
    { "LETTER": "1", "WIDTH": 3, "HEIGHT": "4'", "P": 112, "S": 44, "T": 58 },
    { "LETTER": "2", "WIDTH": 4, "HEIGHT": "4'", "P": 184, "S": 75, "T": 62 },
    { "LETTER": "3", "WIDTH": 4, "HEIGHT": "4'", "P": 153, "S": 69, "T": 64 },
    { "LETTER": "4", "WIDTH": 4, "HEIGHT": "4'", "P": 157, "S": 60, "T": 54 },
    { "LETTER": "5", "WIDTH": 4, "HEIGHT": "4'", "P": 176, "S": 75, "T": 63 },
    { "LETTER": "6", "WIDTH": 4, "HEIGHT": "4'", "P": 169, "S": 64, "T": 57 },
    { "LETTER": "7", "WIDTH": 4, "HEIGHT": "4'", "P": 140, "S": 60, "T": 62 },
    { "LETTER": "8", "WIDTH": 4, "HEIGHT": "4'", "P": 192, "S": 64, "T": 58 },
    { "LETTER": "9", "WIDTH": 4, "HEIGHT": "4'", "P": 169, "S": 64, "T": 57 },
    { "LETTER": "'", "WIDTH": 2, "HEIGHT": "4'", "P": 23, "S": 17, "T": 20 },
    { "LETTER": ",", "WIDTH": 2, "HEIGHT": "4'", "P": 23, "S": 17, "T": 20 },
    { "LETTER": "!", "WIDTH": 2, "HEIGHT": "4'", "P": 77, "S": 40, "T": 43 },
    { "LETTER": "#", "WIDTH": 6, "HEIGHT": "4'", "P": 257, "S": 92, "T": 84 },
    { "LETTER": "-", "WIDTH": 3, "HEIGHT": "4'", "P": 33, "S": 20, "T": 24 },
    { "LETTER": ".", "WIDTH": 2, "HEIGHT": "4'", "P": 21, "S": 16, "T": 20 },
    { "LETTER": "?", "WIDTH": 3.5, "HEIGHT": "4'", "P": 105, "S": 57, "T": 52 },
    { "LETTER": "@", "WIDTH": 4, "HEIGHT": "4'", "P": 162, "S": 101, "T": 56 },
    { "LETTER": "$", "WIDTH": 3.5, "HEIGHT": "4'", "P": 141, "S": 60, "T": 60 },
    { "LETTER": "%", "WIDTH": 4, "HEIGHT": "4'", "P": 201, "S": 104, "T": 84 },
    { "LETTER": "^", "WIDTH": 2.5, "HEIGHT": "4'", "P": 25, "S": 21, "T": 24 },
    { "LETTER": "&", "WIDTH": 4, "HEIGHT": "4'", "P": 137, "S": 69, "T": 64 },
    { "LETTER": "*", "WIDTH": 3.5, "HEIGHT": "4'", "P": 92, "S": 52, "T": 42 },
    { "LETTER": "(", "WIDTH": 2.5, "HEIGHT": "4'", "P": 71, "S": 40, "T": 44 },
    { "LETTER": ")", "WIDTH": 2.5, "HEIGHT": "4'", "P": 71, "S": 40, "T": 44 },
    { "LETTER": "_", "WIDTH": 3.5, "HEIGHT": "4'", "P": 52, "S": 26, "T": 30 },
    { "LETTER": "=", "WIDTH": 3, "HEIGHT": "4'", "P": 76, "S": 35, "T": 32 },
    { "LETTER": "+", "WIDTH": 3, "HEIGHT": "4'", "P": 81, "S": 32, "T": 36 },


    { "LETTER": "A", "WIDTH": 5, "HEIGHT": "5'", "P": 276, "S": 94, "T": 82 },
    { "LETTER": "B", "WIDTH": 5, "HEIGHT": "5'", "P": 307, "S": 104, "T": 150 },
    { "LETTER": "C", "WIDTH": 5, "HEIGHT": "5'", "P": 242, "S": 148, "T": 101 },
    { "LETTER": "D", "WIDTH": 5, "HEIGHT": "5'", "P": 274, "S": 146, "T": 146 },
    { "LETTER": "E", "WIDTH": 5, "HEIGHT": "5'", "P": 280, "S": 106, "T": 106 },
    { "LETTER": "F", "WIDTH": 5, "HEIGHT": "5'", "P": 226, "S": 86, "T": 88 },
    { "LETTER": "G", "WIDTH": 5, "HEIGHT": "5'", "P": 292, "S": 110, "T": 107 },
    { "LETTER": "H", "WIDTH": 5, "HEIGHT": "5'", "P": 306, "S": 102, "T": 94 },
    { "LETTER": "I", "WIDTH": 4, "HEIGHT": "5'", "P": 182, "S": 70, "T": 74 },
    { "LETTER": "J", "WIDTH": 5, "HEIGHT": "5'", "P": 188, "S": 74, "T": 78 },
    { "LETTER": "K", "WIDTH": 5, "HEIGHT": "5'", "P": 270, "S": 109, "T": 145 },
    { "LETTER": "L", "WIDTH": 5, "HEIGHT": "5'", "P": 246, "S": 74, "T": 78 },
    { "LETTER": "M", "WIDTH": 7, "HEIGHT": "5'", "P": 446, "S": 138, "T": 109 },
    { "LETTER": "N", "WIDTH": 5, "HEIGHT": "5'", "P": 328, "S": 108, "T": 82 },
    { "LETTER": "O", "WIDTH": 5, "HEIGHT": "5'", "P": 256, "S": 96, "T": 146 },
    { "LETTER": "P", "WIDTH": 5, "HEIGHT": "5'", "P": 296, "S": 88, "T": 88 },
    { "LETTER": "Q", "WIDTH": 5, "HEIGHT": "5'", "P": 286, "S": 105, "T": 94 },
    { "LETTER": "R", "WIDTH": 5, "HEIGHT": "5'", "P": 346, "S": 108, "T": 105 },
    { "LETTER": "S", "WIDTH": 5, "HEIGHT": "5'", "P": 348, "S": 120, "T": 104 },
    { "LETTER": "T", "WIDTH": 5, "HEIGHT": "5'", "P": 210, "S": 78, "T": 82 },
    { "LETTER": "U", "WIDTH": 5, "HEIGHT": "5'", "P": 296, "S": 90, "T": 94 },
    { "LETTER": "V", "WIDTH": 5, "HEIGHT": "5'", "P": 187, "S": 89, "T": 88 },
    { "LETTER": "W", "WIDTH": 7, "HEIGHT": "5'", "P": 305, "S": 135, "T": 116 },
    { "LETTER": "X", "WIDTH": 5, "HEIGHT": "5'", "P": 230, "S": 116, "T": 108 },
    { "LETTER": "Y", "WIDTH": 5, "HEIGHT": "5'", "P": 247, "S": 83, "T": 78 },
    { "LETTER": "Z", "WIDTH": 5, "HEIGHT": "5'", "P": 258, "S": 110, "T": 110 },

    { "LETTER": " ", "WIDTH": 2, "HEIGHT": "5'", "P": 0, "S": 0, "T": 0 },
    { "LETTER": "0", "WIDTH": 5, "HEIGHT": "5'", "P": 258, "S": 92, "T": 92 },
    { "LETTER": "1", "WIDTH": 3, "HEIGHT": "5'", "P": 131, "S": 100, "T": 54 },
    { "LETTER": "2", "WIDTH": 4, "HEIGHT": "5'", "P": 218, "S": 90, "T": 89 },
    { "LETTER": "3", "WIDTH": 4, "HEIGHT": "5'", "P": 250, "S": 87, "T": 90 },
    { "LETTER": "4", "WIDTH": 4, "HEIGHT": "5'", "P": 201, "S": 70, "T": 67 },
    { "LETTER": "5", "WIDTH": 4, "HEIGHT": "5'", "P": 224, "S": 91, "T": 88 },
    { "LETTER": "6", "WIDTH": 4, "HEIGHT": "5'", "P": 247, "S": 78, "T": 76 },
    { "LETTER": "7", "WIDTH": 4, "HEIGHT": "5'", "P": 155, "S": 68, "T": 70 },
    { "LETTER": "8", "WIDTH": 4, "HEIGHT": "5'", "P": 242, "S": 88, "T": 82 },
    { "LETTER": "9", "WIDTH": 4, "HEIGHT": "5'", "P": 247, "S": 78, "T": 76 },
    { "LETTER": "'", "WIDTH": 2, "HEIGHT": "5'", "P": 29, "S": 18, "T": 22 },
    { "LETTER": ",", "WIDTH": 2, "HEIGHT": "5'", "P": 29, "S": 18, "T": 22 },
    { "LETTER": "!", "WIDTH": 2.5, "HEIGHT": "5'", "P": 113, "S": 99, "T": 98 },
    { "LETTER": "#", "WIDTH": 6, "HEIGHT": "5'", "P": 294, "S": 108, "T": 150 },
    { "LETTER": "-", "WIDTH": 3, "HEIGHT": "5'", "P": 38, "S": 22, "T": 26 },
    { "LETTER": ".", "WIDTH": 2, "HEIGHT": "5'", "P": 21, "S": 16, "T": 20 },
    { "LETTER": "?", "WIDTH": 4, "HEIGHT": "5'", "P": 135, "S": 73, "T": 73 },
    { "LETTER": "@", "WIDTH": 5, "HEIGHT": "5'", "P": 265, "S": 82, "T": 74 },
    { "LETTER": "$", "WIDTH": 4, "HEIGHT": "5'", "P": 219, "S": 82, "T": 76 },
    { "LETTER": "%", "WIDTH": 5, "HEIGHT": "5'", "P": 239, "S": 128, "T": 102 },
    { "LETTER": "^", "WIDTH": 3, "HEIGHT": "5'", "P": 39, "S": 27, "T": 30 },
    { "LETTER": "&", "WIDTH": 5, "HEIGHT": "5'", "P": 203, "S": 85, "T": 82 },
    { "LETTER": "*", "WIDTH": 3.5, "HEIGHT": "5'", "P": 101, "S": 52, "T": 43 },
    { "LETTER": "(", "WIDTH": 3, "HEIGHT": "5'", "P": 101, "S": 52, "T": 53 },
    { "LETTER": ")", "WIDTH": 3, "HEIGHT": "5'", "P": 101, "S": 52, "T": 53 },
    { "LETTER": "_", "WIDTH": 4, "HEIGHT": "5'", "P": 81, "S": 32, "T": 36 },
    { "LETTER": "=", "WIDTH": 3, "HEIGHT": "5'", "P": 134, "S": 52, "T": 40 },
    { "LETTER": "+", "WIDTH": 3.5, "HEIGHT": "5'", "P": 152, "S": 44, "T": 52 },

]

export const horseBackFontData = [

    { "LETTER": "A", "WIDTH": 5, "HEIGHT": "4'", "P": 166, "S": 71, "T": 67 },
    { "LETTER": "B", "WIDTH": 4, "HEIGHT": "4'", "P": 181, "S": 74, "T": 68 },
    { "LETTER": "C", "WIDTH": 4, "HEIGHT": "4'", "P": 137, "S": 70, "T": 74 },
    { "LETTER": "D", "WIDTH": 4, "HEIGHT": "4'", "P": 169, "S": 71, "T": 71 },
    { "LETTER": "E", "WIDTH": 4, "HEIGHT": "4'", "P": 178, "S": 77, "T": 74 },
    { "LETTER": "F", "WIDTH": 4, "HEIGHT": "4'", "P": 198, "S": 65, "T": 64 },
    { "LETTER": "G", "WIDTH": 4, "HEIGHT": "4'", "P": 179, "S": 82, "T": 75 },
    { "LETTER": "H", "WIDTH": 4, "HEIGHT": "4'", "P": 168, "S": 74, "T": 78 },
    { "LETTER": "I", "WIDTH": 3, "HEIGHT": "4'", "P": 148, "S": 46, "T": 100 },
    { "LETTER": "J", "WIDTH": 3, "HEIGHT": "4'", "P": 88, "S": 44, "T": 98 },
    { "LETTER": "K", "WIDTH": 4, "HEIGHT": "4'", "P": 152, "S": 78, "T": 77 },
    { "LETTER": "L", "WIDTH": 4, "HEIGHT": "4'", "P": 58, "S": 62, "T": 62 },
    { "LETTER": "M", "WIDTH": 6, "HEIGHT": "4'", "P": 295, "S": 105, "T": 149 },
    { "LETTER": "N", "WIDTH": 4, "HEIGHT": "4'", "P": 183, "S": 66, "T": 62 },
    { "LETTER": "O", "WIDTH": 4, "HEIGHT": "4'", "P": 158, "S": 70, "T": 70 },
    { "LETTER": "P", "WIDTH": 4, "HEIGHT": "4'", "P": 159, "S": 65, "T": 63 },
    { "LETTER": "Q", "WIDTH": 4, "HEIGHT": "4'", "P": 162, "S": 70, "T": 68 },
    { "LETTER": "R", "WIDTH": 4, "HEIGHT": "4'", "P": 192, "S": 78, "T": 67 },
    { "LETTER": "S", "WIDTH": 4, "HEIGHT": "4'", "P": 187, "S": 88, "T": 76 },
    { "LETTER": "T", "WIDTH": 4, "HEIGHT": "4'", "P": 129, "S": 58, "T": 62 },
    { "LETTER": "U", "WIDTH": 4, "HEIGHT": "4'", "P": 138, "S": 66, "T": 70 },
    { "LETTER": "V", "WIDTH": 4, "HEIGHT": "4'", "P": 119, "S": 59, "T": 60 },
    { "LETTER": "W", "WIDTH": 7, "HEIGHT": "4'", "P": 223, "S": 103, "T": 148 },
    { "LETTER": "X", "WIDTH": 4, "HEIGHT": "4'", "P": 199, "S": 78, "T": 78 },
    { "LETTER": "Y", "WIDTH": 4, "HEIGHT": "4'", "P": 124, "S": 62, "T": 64 },
    { "LETTER": "Z", "WIDTH": 4, "HEIGHT": "4'", "P": 163, "S": 80, "T": 80 },

    { "LETTER": " ", "WIDTH": 2, "HEIGHT": "4'", "P": 0, "S": 0, "T": 0 },
    { "LETTER": "0", "WIDTH": 3.5, "HEIGHT": "4'", "P": 140, "S": 64, "T": 64 },
    { "LETTER": "1", "WIDTH": 3, "HEIGHT": "4'", "P": 89, "S": 42, "T": 96 },
    { "LETTER": "2", "WIDTH": 3.5, "HEIGHT": "4'", "P": 151, "S": 73, "T": 67 },
    { "LETTER": "3", "WIDTH": 3.5, "HEIGHT": "4'", "P": 133, "S": 71, "T": 66 },
    { "LETTER": "4", "WIDTH": 3.5, "HEIGHT": "4'", "P": 134, "S": 58, "T": 52 },
    { "LETTER": "5", "WIDTH": 3.5, "HEIGHT": "4'", "P": 162, "S": 78, "T": 64 },
    { "LETTER": "6", "WIDTH": 3, "HEIGHT": "4'", "P": 131, "S": 60, "T": 54 },
    { "LETTER": "7", "WIDTH": 4, "HEIGHT": "4'", "P": 140, "S": 62, "T": 63 },
    { "LETTER": "8", "WIDTH": 3, "HEIGHT": "4'", "P": 140, "S": 60, "T": 54 },
    { "LETTER": "9", "WIDTH": 3, "HEIGHT": "4'", "P": 131, "S": 60, "T": 54 },
    { "LETTER": "'", "WIDTH": 2, "HEIGHT": "4'", "P": 16, "S": 15, "T": 19 },
    { "LETTER": ",", "WIDTH": 2, "HEIGHT": "4'", "P": 16, "S": 15, "T": 19 },
    { "LETTER": "!", "WIDTH": 2, "HEIGHT": "4'", "P": 53, "S": 35, "T": 34 },
    { "LETTER": "#", "WIDTH": 4, "HEIGHT": "4'", "P": 174, "S": 76, "T": 67 },
    { "LETTER": "-", "WIDTH": 2.5, "HEIGHT": "4'", "P": 28, "S": 18, "T": 22 },
    { "LETTER": ".", "WIDTH": 2, "HEIGHT": "4'", "P": 12, "S": 12, "T": 16 },
    { "LETTER": "?", "WIDTH": 3, "HEIGHT": "4'", "P": 79, "S": 53, "T": 51 },
    { "LETTER": "@", "WIDTH": 4.5, "HEIGHT": "4'", "P": 153, "S": 118, "T": 75 },
    { "LETTER": "$", "WIDTH": 2.5, "HEIGHT": "4'", "P": 81, "S": 55, "T": 53 },
    { "LETTER": "%", "WIDTH": 4, "HEIGHT": "4'", "P": 119, "S": 102, "T": 84 },
    { "LETTER": "^", "WIDTH": 3, "HEIGHT": "4'", "P": 26, "S": 29, "T": 32 },
    { "LETTER": "&", "WIDTH": 3.5, "HEIGHT": "4'", "P": 131, "S": 70, "T": 55 },
    { "LETTER": "*", "WIDTH": 2.5, "HEIGHT": "4'", "P": 51, "S": 36, "T": 33 },
    { "LETTER": "(", "WIDTH": 2.5, "HEIGHT": "4'", "P": 58, "S": 38, "T": 42 },
    { "LETTER": ")", "WIDTH": 2.5, "HEIGHT": "4'", "P": 58, "S": 38, "T": 42 },
    { "LETTER": "_", "WIDTH": 3.5, "HEIGHT": "4'", "P": 36, "S": 24, "T": 28 },
    { "LETTER": "=", "WIDTH": 3, "HEIGHT": "4'", "P": 56, "S": 40, "T": 30 },
    { "LETTER": "+", "WIDTH": 3.5, "HEIGHT": "4'", "P": 73, "S": 36, "T": 40 },


    { "LETTER": "A", "WIDTH": 6, "HEIGHT": "5'", "P": 257, "S": 93, "T": 87 },
    { "LETTER": "B", "WIDTH": 5, "HEIGHT": "5'", "P": 284, "S": 148, "T": 92 },
    { "LETTER": "C", "WIDTH": 5, "HEIGHT": "5'", "P": 209, "S": 95, "T": 148 },
    { "LETTER": "D", "WIDTH": 5, "HEIGHT": "5'", "P": 296, "S": 92, "T": 92 },
    { "LETTER": "E", "WIDTH": 5, "HEIGHT": "5'", "P": 270, "S": 102, "T": 150 },
    { "LETTER": "F", "WIDTH": 5, "HEIGHT": "5'", "P": 221, "S": 84, "T": 85 },
    { "LETTER": "G", "WIDTH": 5, "HEIGHT": "5'", "P": 263, "S": 109, "T": 103 },
    { "LETTER": "H", "WIDTH": 5, "HEIGHT": "5'", "P": 261, "S": 92, "T": 146 },
    { "LETTER": "I", "WIDTH": 3, "HEIGHT": "5'", "P": 142, "S": 54, "T": 58 },
    { "LETTER": "J", "WIDTH": 3.5, "HEIGHT": "5'", "P": 126, "S": 54, "T": 58 },
    { "LETTER": "K", "WIDTH": 5, "HEIGHT": "5'", "P": 240, "S": 147, "T": 146 },
    { "LETTER": "L", "WIDTH": 5, "HEIGHT": "5'", "P": 191, "S": 72, "T": 76 },
    { "LETTER": "M", "WIDTH": 7, "HEIGHT": "5'", "P": 382, "S": 123, "T": 117 },
    { "LETTER": "N", "WIDTH": 5, "HEIGHT": "5'", "P": 293, "S": 86, "T": 82 },
    { "LETTER": "O", "WIDTH": 5, "HEIGHT": "5'", "P": 214, "S": 92, "T": 92 },
    { "LETTER": "P", "WIDTH": 5, "HEIGHT": "5'", "P": 236, "S": 84, "T": 84 },
    { "LETTER": "Q", "WIDTH": 5, "HEIGHT": "5'", "P": 226, "S": 92, "T": 90 },
    { "LETTER": "R", "WIDTH": 5, "HEIGHT": "5'", "P": 285, "S": 104, "T": 146 },
    { "LETTER": "S", "WIDTH": 5, "HEIGHT": "5'", "P": 275, "S": 114, "T": 110 },
    { "LETTER": "T", "WIDTH": 5, "HEIGHT": "5'", "P": 211, "S": 78, "T": 82 },
    { "LETTER": "U", "WIDTH": 5, "HEIGHT": "5'", "P": 231, "S": 84, "T": 88 },
    { "LETTER": "V", "WIDTH": 5, "HEIGHT": "5'", "P": 160, "S": 80, "T": 81 },
    { "LETTER": "W", "WIDTH": 8, "HEIGHT": "5'", "P": 327, "S": 129, "T": 124 },
    { "LETTER": "X", "WIDTH": 5, "HEIGHT": "5'", "P": 223, "S": 148, "T": 146 },
    { "LETTER": "Y", "WIDTH": 5, "HEIGHT": "5'", "P": 178, "S": 79, "T": 81 },
    { "LETTER": "Z", "WIDTH": 5, "HEIGHT": "5'", "P": 269, "S": 104, "T": 104 },

    { "LETTER": " ", "WIDTH": 2, "HEIGHT": "5'", "P": 0, "S": 0, "T": 0 },
    { "LETTER": "0", "WIDTH": 4, "HEIGHT": "5'", "P": 230, "S": 84, "T": 84 },
    { "LETTER": "1", "WIDTH": 3, "HEIGHT": "5'", "P": 133, "S": 90, "T": 54 },
    { "LETTER": "2", "WIDTH": 4, "HEIGHT": "5'", "P": 213, "S": 90, "T": 88 },
    { "LETTER": "3", "WIDTH": 4, "HEIGHT": "5'", "P": 246, "S": 89, "T": 88 },
    { "LETTER": "4", "WIDTH": 4, "HEIGHT": "5'", "P": 190, "S": 72, "T": 68 },
    { "LETTER": "5", "WIDTH": 4, "HEIGHT": "5'", "P": 215, "S": 90, "T": 83 },
    { "LETTER": "6", "WIDTH": 4, "HEIGHT": "5'", "P": 211, "S": 82, "T": 78 },
    { "LETTER": "7", "WIDTH": 4, "HEIGHT": "5'", "P": 178, "S": 74, "T": 76 },
    { "LETTER": "8", "WIDTH": 4, "HEIGHT": "5'", "P": 230, "S": 80, "T": 74 },
    { "LETTER": "9", "WIDTH": 4, "HEIGHT": "5'", "P": 211, "S": 82, "T": 78 },
    { "LETTER": "'", "WIDTH": 2, "HEIGHT": "5'", "P": 23, "S": 17, "T": 21 },
    { "LETTER": ",", "WIDTH": 2, "HEIGHT": "5'", "P": 23, "S": 17, "T": 21 },
    { "LETTER": "!", "WIDTH": 2, "HEIGHT": "5'", "P": 82, "S": 44, "T": 98 },
    { "LETTER": "#", "WIDTH": 6, "HEIGHT": "5'", "P": 294, "S": 108, "T": 150 },
    { "LETTER": "-", "WIDTH": 3, "HEIGHT": "5'", "P": 38, "S": 22, "T": 26 },
    { "LETTER": ".", "WIDTH": 2, "HEIGHT": "5'", "P": 21, "S": 16, "T": 20 },
    { "LETTER": "?", "WIDTH": 3.5, "HEIGHT": "5'", "P": 130, "S": 67, "T": 65 },
    { "LETTER": "@", "WIDTH": 5, "HEIGHT": "5'", "P": 236, "S": 144, "T": 107 },
    { "LETTER": "$", "WIDTH": 3, "HEIGHT": "5'", "P": 126, "S": 70, "T": 66 },
    { "LETTER": "%", "WIDTH": 4.5, "HEIGHT": "5'", "P": 166, "S": 128, "T": 116 },
    { "LETTER": "^", "WIDTH": 3, "HEIGHT": "5'", "P": 52, "S": 34, "T": 37 },
    { "LETTER": "&", "WIDTH": 4.5, "HEIGHT": "5'", "P": 223, "S": 103, "T": 88 },
    { "LETTER": "*", "WIDTH": 3, "HEIGHT": "5'", "P": 74, "S": 43, "T": 42 },
    { "LETTER": "(", "WIDTH": 2.5, "HEIGHT": "5'", "P": 76, "S": 52, "T": 55 },
    { "LETTER": ")", "WIDTH": 2.5, "HEIGHT": "5'", "P": 76, "S": 52, "T": 55 },
    { "LETTER": "_", "WIDTH": 3.5, "HEIGHT": "5'", "P": 52, "S": 26, "T": 30 },
    { "LETTER": "=", "WIDTH": 3, "HEIGHT": "5'", "P": 22, "S": 35, "T": 32 },
    { "LETTER": "+", "WIDTH": 3.5, "HEIGHT": "5'", "P": 93, "S": 44, "T": 52 },


]

export const primeTimeFontData = [

    { "LETTER": 'A', "WIDTH": 4, "HEIGHT": "4'", "P": 153, "S": 61, "T": 57 },
    { "LETTER": 'B', "WIDTH": 4, "HEIGHT": "4'", "P": 215, "S": 71, "T": 67 },
    { "LETTER": 'C', "WIDTH": 4, "HEIGHT": "4'", "P": 167, "S": 72, "T": 76 },
    { "LETTER": 'D', "WIDTH": 4, "HEIGHT": "4'", "P": 246, "S": 68, "T": 68 },
    { "LETTER": 'E', "WIDTH": 4, "HEIGHT": "4'", "P": 194, "S": 77, "T": 81 },
    { "LETTER": 'F', "WIDTH": 4, "HEIGHT": "4'", "P": 151, "S": 60, "T": 64 },
    { "LETTER": 'G', "WIDTH": 4, "HEIGHT": "4'", "P": 202, "S": 77, "T": 69 },
    { "LETTER": 'H', "WIDTH": 4, "HEIGHT": "4'", "P": 185, "S": 66, "T": 70 },
    { "LETTER": 'I', "WIDTH": 2, "HEIGHT": "4'", "P": 81, "S": 32, "T": 36 },
    { "LETTER": 'J', "WIDTH": 4, "HEIGHT": "4'", "P": 112, "S": 53, "T": 52 },
    { "LETTER": 'K', "WIDTH": 4, "HEIGHT": "4'", "P": 170, "S": 74, "T": 73 },
    { "LETTER": 'L', "WIDTH": 4, "HEIGHT": "4'", "P": 121, "S": 55, "T": 52 },
    { "LETTER": 'M', "WIDTH": 4, "HEIGHT": "4'", "P": 225, "S": 65, "T": 61 },
    { "LETTER": 'N', "WIDTH": 4, "HEIGHT": "4'", "P": 213, "S": 70, "T": 68 },
    { "LETTER": 'O', "WIDTH": 4, "HEIGHT": "4'", "P": 186, "S": 68, "T": 68 },
    { "LETTER": 'P', "WIDTH": 4, "HEIGHT": "4'", "P": 168, "S": 60, "T": 59 },
    { "LETTER": 'Q', "WIDTH": 4, "HEIGHT": "4'", "P": 201, "S": 74, "T": 64 },
    { "LETTER": 'R', "WIDTH": 4, "HEIGHT": "4'", "P": 210, "S": 74, "T": 70 },
    { "LETTER": 'S', "WIDTH": 4, "HEIGHT": "4'", "P": 187, "S": 84, "T": 68 },
    { "LETTER": 'T', "WIDTH": 4, "HEIGHT": "4'", "P": 121, "S": 54, "T": 52 },
    { "LETTER": 'U', "WIDTH": 4, "HEIGHT": "4'", "P": 174, "S": 66, "T": 70 },
    { "LETTER": 'V', "WIDTH": 4, "HEIGHT": "4'", "P": 151, "S": 59, "T": 60 },
    { "LETTER": 'W', "WIDTH": 6, "HEIGHT": "4'", "P": 289, "S": 102, "T": 106 },
    { "LETTER": 'X', "WIDTH": 4, "HEIGHT": "4'", "P": 167, "S": 74, "T": 72 },
    { "LETTER": 'Y', "WIDTH": 4, "HEIGHT": "4'", "P": 132, "S": 58, "T": 60 },
    { "LETTER": 'Z', "WIDTH": 4, "HEIGHT": "4'", "P": 181, "S": 72, "T": 72 },

    { "LETTER": " ", "WIDTH": 2, "HEIGHT": "4'", "P": 0, "S": 0, "T": 0 },
    { "LETTER": "0", "WIDTH": 4, "HEIGHT": "4'", "P": 186, "S": 68, "T": 68 },
    { "LETTER": "1", "WIDTH": 2.5, "HEIGHT": "4'", "P": 91, "S": 36, "T": 40 },
    { "LETTER": "2", "WIDTH": 4, "HEIGHT": "4'", "P": 169, "S": 73, "T": 71 },
    { "LETTER": "3", "WIDTH": 4, "HEIGHT": "4'", "P": 160, "S": 73, "T": 64 },
    { "LETTER": "4", "WIDTH": 4, "HEIGHT": "4'", "P": 161, "S": 60, "T": 64 },
    { "LETTER": "5", "WIDTH": 4, "HEIGHT": "4'", "P": 175, "S": 74, "T": 71 },
    { "LETTER": "6", "WIDTH": 4, "HEIGHT": "4'", "P": 174, "S": 70, "T": 63 },
    { "LETTER": "7", "WIDTH": 4, "HEIGHT": "4'", "P": 127, "S": 60, "T": 62 },
    { "LETTER": "8", "WIDTH": 4, "HEIGHT": "4'", "P": 205, "S": 70, "T": 64 },
    { "LETTER": "9", "WIDTH": 4, "HEIGHT": "4'", "P": 174, "S": 70, "T": 63 },
    { "LETTER": "'", "WIDTH": 2, "HEIGHT": "4'", "P": 23, "S": 17, "T": 21 },
    { "LETTER": ",", "WIDTH": 2, "HEIGHT": "4'", "P": 23, "S": 17, "T": 21 },
    { "LETTER": "!", "WIDTH": 2, "HEIGHT": "4'", "P": 74, "S": 37, "T": 36 },
    { "LETTER": "#", "WIDTH": 5, "HEIGHT": "4'", "P": 230, "S": 84, "T": 76 },
    { "LETTER": "-", "WIDTH": 3, "HEIGHT": "4'", "P": 38, "S": 22, "T": 26 },
    { "LETTER": ".", "WIDTH": 2, "HEIGHT": "4'", "P": 21, "S": 16, "T": 20 },
    { "LETTER": "?", "WIDTH": 4, "HEIGHT": "4'", "P": 120, "S": 60, "T": 53 },
    { "LETTER": "@", "WIDTH": 4, "HEIGHT": "4'", "P": 162, "S": 106, "T": 56 },
    { "LETTER": "$", "WIDTH": 3.5, "HEIGHT": "4'", "P": 141, "S": 60, "T": 60 },
    { "LETTER": "%", "WIDTH": 4, "HEIGHT": "4'", "P": 153, "S": 104, "T": 84 },
    { "LETTER": "^", "WIDTH": 2.5, "HEIGHT": "4'", "P": 25, "S": 21, "T": 24 },
    { "LETTER": "&", "WIDTH": 4, "HEIGHT": "4'", "P": 137, "S": 69, "T": 64 },
    { "LETTER": "*", "WIDTH": 3.5, "HEIGHT": "4'", "P": 92, "S": 55, "T": 42 },
    { "LETTER": "(", "WIDTH": 2.5, "HEIGHT": "4'", "P": 71, "S": 40, "T": 44 },
    { "LETTER": ")", "WIDTH": 2.5, "HEIGHT": "4'", "P": 71, "S": 40, "T": 44 },
    { "LETTER": "_", "WIDTH": 3.5, "HEIGHT": "4'", "P": 53, "S": 26, "T": 30 },
    { "LETTER": "=", "WIDTH": 3, "HEIGHT": "4'", "P": 76, "S": 35, "T": 32 },
    { "LETTER": "+", "WIDTH": 3, "HEIGHT": "4'", "P": 81, "S": 32, "T": 36 },
    

    { "LETTER": 'A', "WIDTH": 5, "HEIGHT": "5'", "P": 271, "S": 73, "T": 73 },
    { "LETTER": 'B', "WIDTH": 5, "HEIGHT": "5'", "P": 373, "S": 93, "T": 89 },
    { "LETTER": 'C', "WIDTH": 5, "HEIGHT": "5'", "P": 247, "S": 88, "T": 92 },
    { "LETTER": 'D', "WIDTH": 5, "HEIGHT": "5'", "P": 320, "S": 88, "T": 88 },
    { "LETTER": 'E', "WIDTH": 5, "HEIGHT": "5'", "P": 352, "S": 103, "T": 107 },
    { "LETTER": 'F', "WIDTH": 5, "HEIGHT": "5'", "P": 271, "S": 80, "T": 84 },
    { "LETTER": 'G', "WIDTH": 5, "HEIGHT": "5'", "P": 321, "S": 106, "T": 146 },
    { "LETTER": 'H', "WIDTH": 5, "HEIGHT": "5'", "P": 308, "S": 84, "T": 88 },
    { "LETTER": 'I', "WIDTH": 2.5, "HEIGHT": "5'", "P": 131, "S": 40, "T": 44 },
    { "LETTER": 'J', "WIDTH": 5, "HEIGHT": "5'", "P": 183, "S": 62, "T": 66 },
    { "LETTER": 'K', "WIDTH": 5, "HEIGHT": "5'", "P": 261, "S": 87, "T": 86 },
    { "LETTER": 'L', "WIDTH": 5, "HEIGHT": "5'", "P": 208, "S": 62, "T": 66 },
    { "LETTER": 'M', "WIDTH": 5, "HEIGHT": "5'", "P": 354, "S": 90, "T": 87 },
    { "LETTER": 'N', "WIDTH": 5, "HEIGHT": "5'", "P": 370, "S": 90, "T": 88 },
    { "LETTER": 'O', "WIDTH": 5, "HEIGHT": "5'", "P": 288, "S": 88, "T": 86 },
    { "LETTER": 'P', "WIDTH": 5, "HEIGHT": "5'", "P": 288, "S": 77, "T": 77 },
    { "LETTER": 'Q', "WIDTH": 5, "HEIGHT": "5'", "P": 316, "S": 146, "T": 84 },
    { "LETTER": 'R', "WIDTH": 5, "HEIGHT": "5'", "P": 338, "S": 145, "T": 94 },
    { "LETTER": 'S', "WIDTH": 5, "HEIGHT": "5'", "P": 329, "S": 110, "T": 94 },
    { "LETTER": 'T', "WIDTH": 5, "HEIGHT": "5'", "P": 208, "S": 62, "T": 66 },
    { "LETTER": 'U', "WIDTH": 5, "HEIGHT": "5'", "P": 275, "S": 84, "T": 87 },
    { "LETTER": 'V', "WIDTH": 5, "HEIGHT": "5'", "P": 253, "S": 79, "T": 80 },
    { "LETTER": 'W', "WIDTH": 8, "HEIGHT": "5'", "P": 436, "S": 117, "T": 112 },
    { "LETTER": 'X', "WIDTH": 5, "HEIGHT": "5'", "P": 268, "S": 146, "T": 146 },
    { "LETTER": 'Y', "WIDTH": 5, "HEIGHT": "5'", "P": 246, "S": 70, "T": 73 },
    { "LETTER": 'Z', "WIDTH": 5, "HEIGHT": "5'", "P": 306, "S": 94, "T": 94 },

    { "LETTER": " ", "WIDTH": 2, "HEIGHT": "5'", "P": 0, "S": 0, "T": 0 },
    { "LETTER": "0", "WIDTH": 5, "HEIGHT": "5'", "P": 284, "S": 80, "T": 80 },
    { "LETTER": "1", "WIDTH": 3, "HEIGHT": "5'", "P": 144, "S": 44, "T": 53 },
    { "LETTER": "2", "WIDTH": 4, "HEIGHT": "5'", "P": 269, "S": 84, "T": 84 },
    { "LETTER": "3", "WIDTH": 5, "HEIGHT": "5'", "P": 277, "S": 92, "T": 83 },
    { "LETTER": "4", "WIDTH": 5, "HEIGHT": "5'", "P": 262, "S": 74, "T": 78 },
    { "LETTER": "5", "WIDTH": 5, "HEIGHT": "5'", "P": 306, "S": 147, "T": 87 },
    { "LETTER": "6", "WIDTH": 4, "HEIGHT": "5'", "P": 269, "S": 80, "T": 78 },
    { "LETTER": "7", "WIDTH": 5, "HEIGHT": "5'", "P": 205, "S": 70, "T": 74 },
    { "LETTER": "8", "WIDTH": 4, "HEIGHT": "5'", "P": 289, "S": 76, "T": 70 },
    { "LETTER": "9", "WIDTH": 4, "HEIGHT": "5'", "P": 262, "S": 78, "T": 76 },
    { "LETTER": "'", "WIDTH": 2.5, "HEIGHT": "5'", "P": 34, "S": 22, "T": 25 },
    { "LETTER": ",", "WIDTH": 2.5, "HEIGHT": "5'", "P": 34, "S": 22, "T": 25 },
    { "LETTER": "!", "WIDTH": 2, "HEIGHT": "5'", "P": 123, "S": 58, "T": 44 },
    { "LETTER": "#", "WIDTH": 7, "HEIGHT": "5'", "P": 418, "S": 108, "T": 108 },
    { "LETTER": "-", "WIDTH": 3.5, "HEIGHT": "5'", "P": 67, "S": 28, "T": 32 },
    { "LETTER": ".", "WIDTH": 2, "HEIGHT": "5'", "P": 37, "S": 20, "T": 24 },
    { "LETTER": "?", "WIDTH": 4, "HEIGHT": "5'", "P": 189, "S": 71, "T": 63 },
    { "LETTER": "@", "WIDTH": 5, "HEIGHT": "5'", "P": 265, "S": 82, "T": 74 },
    { "LETTER": "$", "WIDTH": 4, "HEIGHT": "5'", "P": 219, "S": 82, "T": 76 },
    { "LETTER": "%", "WIDTH": 5, "HEIGHT": "5'", "P": 239, "S": 128, "T": 102 },
    { "LETTER": "^", "WIDTH": 3, "HEIGHT": "5'", "P": 39, "S": 27, "T": 30 },
    { "LETTER": "&", "WIDTH": 5, "HEIGHT": "5'", "P": 203, "S": 85, "T": 82 },
    { "LETTER": "*", "WIDTH": 3.5, "HEIGHT": "5'", "P": 102, "S": 56, "T": 43 },
    { "LETTER": "(", "WIDTH": 3, "HEIGHT": "5'", "P": 102, "S": 55, "T": 53 },
    { "LETTER": ")", "WIDTH": 3, "HEIGHT": "5'", "P": 102, "S": 55, "T": 53 },
    { "LETTER": "_", "WIDTH": 4, "HEIGHT": "5'", "P": 81, "S": 32, "T": 36 },
    { "LETTER": "=", "WIDTH": 3, "HEIGHT": "5'", "P": 134, "S": 55, "T": 40 },
    { "LETTER": "+", "WIDTH": 3.5, "HEIGHT": "5'", "P": 153, "S": 44, "T": 53 },
];

export const standardFontData = [


    { "LETTER": "A", "WIDTH": 2.5, "HEIGHT": "3'", "P": 60, "S": 40, "T": 40 },
    { "LETTER": "B", "WIDTH": 2.5, "HEIGHT": "3'", "P": 65, "S": 53, "T": 53 },
    { "LETTER": "C", "WIDTH": 2.5, "HEIGHT": "3'", "P": 51, "S": 44, "T": 44 },
    { "LETTER": "D", "WIDTH": 2.5, "HEIGHT": "3'", "P": 55, "S": 42, "T": 42 },
    { "LETTER": "E", "WIDTH": 2.5, "HEIGHT": "3'", "P": 51, "S": 42, "T": 42 },
    { "LETTER": "F", "WIDTH": 2.5, "HEIGHT": "3'", "P": 42, "S": 40, "T": 40 },
    { "LETTER": "G", "WIDTH": 2.5, "HEIGHT": "3'", "P": 51, "S": 43, "T": 43 },
    { "LETTER": "H", "WIDTH": 2.5, "HEIGHT": "3'", "P": 55, "S": 52, "T": 52 },
    { "LETTER": "I", "WIDTH": 1.5, "HEIGHT": "3'", "P": 25, "S": 35, "T": 35 },
    { "LETTER": "J", "WIDTH": 2.5, "HEIGHT": "3'", "P": 42, "S": 36, "T": 36 },
    { "LETTER": "K", "WIDTH": 2.5, "HEIGHT": "3'", "P": 53, "S": 52, "T": 52 },
    { "LETTER": "L", "WIDTH": 2.5, "HEIGHT": "3'", "P": 32, "S": 30, "T": 30 },
    { "LETTER": "M", "WIDTH": 3, "HEIGHT": "3'", "P": 66, "S": 52, "T": 52 },
    { "LETTER": "N", "WIDTH": 3, "HEIGHT": "3'", "P": 62, "S": 56, "T": 56 },
    { "LETTER": "O", "WIDTH": 2.5, "HEIGHT": "3'", "P": 58, "S": 51, "T": 51 },
    { "LETTER": "P", "WIDTH": 2.5, "HEIGHT": "3'", "P": 51, "S": 42, "T": 42 },
    { "LETTER": "Q", "WIDTH": 2.5, "HEIGHT": "3'", "P": 60, "S": 58, "T": 58 },
    { "LETTER": "R", "WIDTH": 2.5, "HEIGHT": "3'", "P": 61, "S": 42, "T": 42 },
    { "LETTER": "S", "WIDTH": 2.5, "HEIGHT": "3'", "P": 52, "S": 53, "T": 54 },
    { "LETTER": "T", "WIDTH": 2.5, "HEIGHT": "3'", "P": 36, "S": 42, "T": 42 },
    { "LETTER": "U", "WIDTH": 2.5, "HEIGHT": "3'", "P": 52, "S": 55, "T": 56 },
    { "LETTER": "V", "WIDTH": 2.5, "HEIGHT": "3'", "P": 52, "S": 53, "T": 53 },
    { "LETTER": "W", "WIDTH": 3, "HEIGHT": "3'", "P": 56, "S": 56, "T": 56 },
    { "LETTER": "X", "WIDTH": 3, "HEIGHT": "3'", "P": 53, "S": 56, "T": 56 },
    { "LETTER": "Y", "WIDTH": 2.5, "HEIGHT": "3'", "P": 38, "S": 45, "T": 45 },
    { "LETTER": "Z", "WIDTH": 2.5, "HEIGHT": "3'", "P": 53, "S": 55, "T": 103 },

    { "LETTER": " ", "WIDTH": 2, "HEIGHT": "3'", "P": 0, "S": 0, "T": 0 },
    { "LETTER": "1", "WIDTH": 1.5, "HEIGHT": "3'", "P": 25, "S": 35, "T": 35 },
    { "LETTER": "2", "WIDTH": 2.5, "HEIGHT": "3'", "P": 56, "S": 44, "T": 42 },
    { "LETTER": "3", "WIDTH": 2.5, "HEIGHT": "3'", "P": 54, "S": 42, "T": 40 },
    { "LETTER": "4", "WIDTH": 2.5, "HEIGHT": "3'", "P": 44, "S": 34, "T": 38 },
    { "LETTER": "5", "WIDTH": 2.5, "HEIGHT": "3'", "P": 57, "S": 44, "T": 41 },
    { "LETTER": "6", "WIDTH": 2.5, "HEIGHT": "3'", "P": 51, "S": 34, "T": 34 },
    { "LETTER": "7", "WIDTH": 2.5, "HEIGHT": "3'", "P": 35, "S": 28, "T": 32 },
    { "LETTER": "8", "WIDTH": 2.5, "HEIGHT": "3'", "P": 67, "S": 40, "T": 36 },
    { "LETTER": "9", "WIDTH": 2.5, "HEIGHT": "3'", "P": 51, "S": 34, "T": 34 },
    { "LETTER": "0", "WIDTH": 2.5, "HEIGHT": "3'", "P": 60, "S": 40, "T": 40 },
    { "LETTER": "-", "WIDTH": 1.5, "HEIGHT": "3'", "P": 3, "S": 8, "T": 12 },
    { "LETTER": ".", "WIDTH": 1, "HEIGHT": "3'", "P": 1, "S": 4, "T": 8 },
    { "LETTER": "!", "WIDTH": 1.5, "HEIGHT": "3'", "P": 20, "S": 23, "T": 24 },
    { "LETTER": "\\", "WIDTH": 2, "HEIGHT": "3'", "P": 10, "S": 16, "T": 17 },
    { "LETTER": "'", "WIDTH": 1.5, "HEIGHT": "3'", "P": 5, "S": 9, "T": 13 },
    { "LETTER": ",", "WIDTH": 1.5, "HEIGHT": "3'", "P": 5, "S": 9, "T": 13 },
    { "LETTER": "#", "WIDTH": 2.5, "HEIGHT": "3'", "P": 28, "S": 14, "T": 38 },
    { "LETTER": "&", "WIDTH": 3, "HEIGHT": "3'", "P": 73, "S": 51, "T": 40 },
    { "LETTER": "@", "WIDTH": 3, "HEIGHT": "3'", "P": 78, "S": 33, "T": 64 },
    { "LETTER": "$", "WIDTH": 2.5, "HEIGHT": "3.5'", "P": 58, "S": 14, "T": 52 },
    { "LETTER": "%", "WIDTH": 3, "HEIGHT": "3'", "P": 40, "S": 54, "T": 58 },
    { "LETTER": "?", "WIDTH": 2.5, "HEIGHT": "3'", "P": 35, "S": 34, "T": 32 },

]

